/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer{
    padding: 71px 32px 10px;
    background-color: #056FB8;
    color: white;
 &__logo{
    max-width: 219.3px;
    margin: 0 auto 83px;
 }
 &__list{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 1.2rem;
 }
}