/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p {
    &-top {}
    &-top1 {}
    &-top1__list {
        max-width: 968.6px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 132px auto 100px;
        @media only screen and (max-width: 1024px) {
            padding: 0 20px;
            align-items: center;
        }
        @media only screen and (max-width: 516px) {
            margin: 80px auto 100px;
        }
    }
    &-top1__left {
        width: 37%;
        @media only screen and (max-width: 1024px) {
            width: 49%;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 30px;
        }
    }
    &-top1__title {
        font-size: 3.5rem;
        @media only screen and (max-width: 516px) {
            font-size: 2.5rem;
        }
    }
    &-top1__desc {
        margin: 14px 0;
        font-size: 4.5rem;
        color: #056FB8;
        @media only screen and (max-width: 516px) {
            font-size: 3rem;
        }
    }
    &-top1__text {
		font-family: 'Noto Sans JP', sans-serif;
        line-height: 1.8;
    }
    &-top1__image {
        width: 56%;
        @media only screen and (max-width: 1024px) {
            width: 46%;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }
    }
    &-top2 {
        background-color: #F4F4F4;
        padding: 84px 20px 147px;
        text-align: center;
    }
    &-top2__content {
        max-width: 1011.2px;
        margin: 0 auto;
    }
    &-top2__list {
        margin-bottom: 96px;
        margin-top: 89px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        @media only screen and (max-width: 516px) {
            margin-bottom: 20px;
        }
    }
    &-top2__item {
        height: 119px;
        border-radius: 8px;
        width: 31%;
        background-color: #056FB8;
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        position: relative;
		font-family: 'Noto Sans JP', sans-serif;
        @media only screen and (max-width: 1024px) {
            padding: 0 10px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 1.8rem;
        }
        @media only screen and (max-width: 516px) {
            margin-bottom: 40px;
            width: 100%;
        }
        img {
            position: absolute;
            left: 50%;
            bottom: -20px;
            width: 25px;
            transform: translateX(-50%);
        }
    }
    &-top2__image {
        max-width: 753.4px;
        margin: 0 auto;
    }
    &-top3 {
        max-width: 961.6px;
        margin: 131px auto;
        a {
            margin-top: 32px;
            display: flex;
            width: 231.6px;
            height: 58px;
            background-color: #056FB8;
            color: white;
            font-size: 1.8rem;
            border-radius: 8px;
            align-items: center;
            span {
                margin-left: 40px;
                display: block;
                margin-right: 36px;
            }
            img {
                width: 13.7px;
            }
        }
    }
    &-top3__list {
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 848.8px;
        @media only screen and (max-width: 1024px) {
            margin: 0 auto;
            padding: 0 20px;
        }
    }
    &-top3__left {
        width: 47%;
        @media only screen and (max-width: 1024px) {
            width: 54%;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 50px;
        }
        p {
            line-height: 1.8;
            margin: 19px 0;
			font-family: 'Noto Sans JP', sans-serif;
        }
    }
    &-top3__right {
        width: 34%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
        }
    }
    &-top4 {
        padding: 35px 20px 84px;
        background-color: #F7F8F8;
    }
    &-top4__content {
        max-width: 961.6px;
        margin: 0 auto;
    }
    &-top4__list {
        margin-bottom: 2px;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 860.8px;
        @media only screen and (max-width: 1024px) {
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 767px) {
            margin-top: 56px;
        }
    }
    &-top4__left {
        margin-top: 56px;
        width: 38%;
        @media only screen and (max-width: 767px) {
            width: 50%;
            margin-top: 0;
        }
        @media only screen and (max-width: 516px) {
            width: 100%;
            margin-bottom: 30px;
        }
        p {
            line-height: 1.8;
            margin-top: 24px;
			font-family: 'Noto Sans JP', sans-serif;
        }
    }
    &-top4__right {
        width: 27%;
        @media only screen and (max-width: 767px) {
            width: 32%;
        }
        @media only screen and (max-width: 516px) {
            width: 100%;
            max-width: 250px;
            margin: 0 auto;
        }
    }
    &-top4__meta {
        min-height: 108.6px;
        margin-bottom: 16px;
        background-color: #FDD23F;
        padding: 20px 36px;
        display: -webkit-box;
        display: flex;
        border-radius: 8px;
        line-height: 1.8;
        align-items: center;
		font-family: 'Noto Sans JP', sans-serif;
        @media only screen and (max-width: 767px) {
            padding: 20px 20px;
        }
        @media only screen and (max-width: 516px) {
            flex-wrap: wrap;
        }
        span:first-child {
            width: 216px;
            font-size: 2rem;
            @media only screen and (max-width: 767px) {
                width: 138px;
            }
            @media only screen and (max-width: 516px) {
                width: 100%;
                margin-bottom: 10px;
                display: block;
            }
        }
        span:last-child {
            width: 73%;
            font-size: 1.8rem;
            @media only screen and (max-width: 516px) {
                width: 100%;
            }
        }
    }
    &-top4__title {
        font-size: 3rem;
        text-align: center;
        margin-top: 96px;
        margin-bottom: 68px;
        @media only screen and (max-width: 516px) {
            font-size: 2.5rem;
        }
        span {
            display: block;
        }
        img {
            width: 33.5px;
        }
    }
    &-top4__chart {}
    &-top5 {
        padding: 0 20px;
		font-family: 'Noto Sans JP', sans-serif;
        a {
            border-radius: 78px;
            background-color: #FDD23F;
            max-width: 440.2px;
            height: 80px;
            display: flex;
            margin: 36px auto 0;
            align-items: center;
            justify-content: center;
            border: 1px solid #FDD23F;
			font-family: 'Noto Serif JP', serif;
            &:hover {
                background-color: white;
                color: #FDD23F;
                border: 1px solid #FDD23F;
                opacity: 1;
            }
        }
    }
    &-top5__content {
        font-size: 2rem;
        max-width: 1040.7px;
        margin: 144px auto 157px;
        background-color: #F2F2F3;
        text-align: center;
        border-radius: 8px;
        padding: 47px 20px;
        @media only screen and (max-width: 516px) {
            font-size: 1.5rem;
        }
    }
    &-top5__title {
        font-size: 2.2rem;
        &.sp {
            display: none;
        }
        @media only screen and (max-width: 516px) {
            display: none;
            &.sp {
                display: block;
                font-size: 1.8rem;
            }
        }
    }
    &-top5__contact {
        font-size: 6.077rem;
        margin: 34px 0 16px;
        font-family: 'Yantramanav', sans-serif;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 516px) {
            font-size: 3.4rem;
        }
        img {
            width: 39px;
            margin-right: 11px;
            @media only screen and (max-width: 516px) {
                width: 29px;
            }
        }
    }
    &-top5__time {}
}

.c {
    &-mainvisual {}
    &-mainvisual__slider {}
    &-mainvisual__bgr {}
}