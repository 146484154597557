/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p-about {
    &__content {
        font-family: "Noto Sans JP", sans-serif;
        margin: 125px 0 292px;
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            margin: 80px 0;
        }
    }
    &__text {
        padding: 35px 0;
        display: flex;
        border-bottom: 1px solid #056fb8;
        font-size: 1.5rem;
        max-width: 922.5px;
        margin: 0 auto;
        @media only screen and (max-width: 516px) {
            flex-wrap: wrap;
        }
    }
    &__left {
        width: 138px;
        margin-left: 23px;
        @media only screen and (max-width: 516px) {
            margin-left: 0;
            width: 100%;
            margin-bottom: 10px;
        }
    }
    &__right {
        width: 83%;
        @media only screen and (max-width: 516px) {
            width: 100%;
        }
    }
}

.c-title {
    height: 86.2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #056fb8;
    color: white;
    font-size: 2.262rem;
}

.p {
    &-flow__content {
        margin: 148px 0 381px;
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            margin: 80px 0;
        }
    }
    &-flow__meta {
        max-width: 922.5px;
        margin: 0 auto;
        display: -webkit-box;
        display: flex;
        height: 229.7px;
        border: 1px solid #056fb8;
        @media only screen and (max-width: 1024px) {
            height: auto;
        }
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }
    &-flow__number {
        background-color: #056fb8;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 5.027rem;
        color: white;
        width: 129.5px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            font-size: 4rem;
        }
        @media only screen and (max-width: 516px) {
            font-size: 3.5rem;
        }
    }
    &-flow__text {
        padding: 36px 50px;
        width: 86%;
        @media only screen and (max-width: 1024px) {
            padding: 30px;
        }
        @media only screen and (max-width: 767px) {
            padding: 30px 20px;
            width: 100%;
        }
    }
    &-flow__title {
        font-size: 2.424rem;
        @media only screen and (max-width: 516px) {
            font-size: 2rem;
        }
    }
    &-flow__info {
        margin-top: 11px;
        font-family: "Noto Sans JP", sans-serif;
        line-height: 1.8;
    }
    &-contact {
        margin-top: 28px;
        color: #056fb8;
        font-family: "Noto Sans JP", sans-serif;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 567px;
        @media only screen and (max-width: 767px) {
            max-width: 100%;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &-contact__left {
        text-align: center;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    &-contact__text {
        font-size: 1.6rem;
        @media only screen and (max-width: 516px) {
            font-size: 1.5rem;
        }
    }
    &-conatct__phone {
        font-size: 3.368rem;
        font-family: "Yantramanav", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 516px) {
            font-size: 3.2rem;
        }
        img {
            width: 21.5px;
            margin-right: 8px;
        }
        span {
            letter-spacing: 2px;
        }
    }
    &-contact__right {
        text-align: center;
    }
    &-contact__link {
        margin-top: 6px;
        width: 239.3px;
        height: 30px;
        background-color: #056fb8;
        color: white;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 5px;
    }
    &-flow__arrow {
        width: 73.2px;
        margin: 53px auto;
        @media only screen and (max-width: 767px) {
            width: 60.2px;
            margin: 40px auto;
        }
    }
    &-flow__list {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 516px) {
            flex-wrap: wrap;
        }
    }
    &-flow__left {
        width: 61%;
        @media only screen and (max-width: 516px) {
            width: 100%;
            margin-bottom: 30px;
        }
    }
    &-flow__right {
        &1 {
            margin-right: -14px;
            width: 25%;
            margin-top: -5px;
            @media only screen and (max-width: 767px) {
                margin-right: 0;
                width: 30%;
                margin-top: 0;
            }
            @media only screen and (max-width: 516px) {
                width: 100%;
                max-width: 200px;
                margin: 0 auto;
            }
        }
        &2 {
            margin-top: -22px;
            width: 22%;
            margin-right: 10px;
            @media only screen and (max-width: 767px) {
                margin-top: 0;
                width: 30%;
                margin-right: 5px;
            }
            @media only screen and (max-width: 516px) {
                width: 100%;
                max-width: 200px;
                margin: 0 auto;
            }
        }
        &3 {
            margin-top: 12px;
            width: 26%;
            @media only screen and (max-width: 767px) {
                margin-top: 0;
                width: 30%;
            }
            @media only screen and (max-width: 516px) {
                width: 100%;
                max-width: 200px;
                margin: 0 auto;
            }
        }
    }
}

.p {
    &-service__main {}
    &-service__content {
        max-width: 961.9px;
        margin: 162px auto 117px;
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            margin: 80px auto;
        }
    }
    &-service__title {
        font-size: 3.5rem;
        @media only screen and (max-width: 767px) {
            font-size: 3rem;
        }
        @media only screen and (max-width: 516px) {
            font-size: 2.5rem;
        }
    }
    &-service__list {
        margin-top: 66px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &-service__image {
        width: 53%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 30px;
        }
    }
    &-service__text {
        text-align: center;
        margin-top: 5px;
        width: 40%;
        font-size: 2.5rem;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
        span {}
        p {
            margin-top: 10px;
            border: 2px solid;
            background-color: #FDD23F;
            font-size: 2.04rem;
            font-family: "Noto Sans JP", sans-serif;
            border-radius: 8px;
            line-height: 2;
            text-align: left;
            padding: 35px 27px;
            @media only screen and (max-width: 516px) {
                font-size: 1.57rem;
                padding: 35px 20px;
            }
        }
    }
    &-service1 {
        padding-bottom: 100px;
        padding-top: 86px;
        background-color: #F4F4F4;
        &__content {
            max-width: 961.9px;
            margin: 0 auto;
            padding: 0 20px;
            @media only screen and (max-width: 1024px) {
                max-width: 580px;
            }
        }
    }
    &-service1__list {
        margin-left: 15px;
        margin-top: 64px;
        font-family: "Noto Sans JP", sans-serif;
        display: -webkit-box;
        display: flex;
        position: relative;
        @media only screen and (max-width: 1024px) {
            display: block;
        }
        @media only screen and (max-width: 516px) {
            margin-left: 0;
        }
    }
    &-service1__left {
        width: 59%;
        font-size: 2rem;
        @media only screen and (max-width: 1024px) {
            width: 100%;
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 516px) {
            font-size: 1.55rem;
        }
    }
    &-service1__text {
        margin-bottom: 57px;
        span {
            display: inline-block;
            border-radius: 5px;
            background-color: #056fb8;
            color: white;
            padding: 11px 23px;
            margin-bottom: 12px;
        }
        p {
            line-height: 2.3;
        }
    }
    &-service1__image {
        width: 46%;
        position: absolute;
        right: 7px;
        @media only screen and (max-width: 1024px) {
            position: relative;
            width: 419.9px;
            margin: 0 auto;
        }
        @media only screen and (max-width: 516px) {
            width: 279.9px;
            right: 0;
        }
    }
    &-service1__chat {
        position: relative;
        span {
            top: 12%;
            position: absolute;
            line-height: 1.8;
            left: 9.5%;
            letter-spacing: 1px;
            @media only screen and (max-width: 516px) {
                left: 8%;
                font-size: 1rem;
            }
        }
    }
    &-service2 {
        max-width: 961.9px;
        margin: 112px auto 180px;
        padding: 0 20px;
        @media only screen and (max-width: 516px) {
            margin: 80px auto;
        }
    }
    &-service2__content {
        margin: 61px 0 61px 16px;
        font-family: "Noto Sans JP", sans-serif;
        font-size: 2rem;
        @media only screen and (max-width: 1024px) {
            margin: 61px 0;
        }
        @media only screen and (max-width: 516px) {
            font-size: 1.55rem;
        }
        table,
        tr,
        td {
            border: 1px solid;
            border-collapse: collapse;
        }
        table {
            text-align: center;
            margin-top: 38px;
            width: 100%;
            margin-bottom: 85px;
            tr:nth-child(n+2) {
                height: 70px;
            }
        }
    }
    &-service2__info {
        border-radius: 5px;
        background-color: #056fb8;
        color: white;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: 171.3px;
        height: 50.4px;
    }
    &-service2__table {
        height: 90px;
        font-size: 2.3rem;
        background-color: #FDD23F;
        @media only screen and (max-width: 767px) {
            font-size: 1.8rem;
        }
        td:first-child {
            width: 41%;
        }
        td:last-child {
            width: 57%;
        }
    }
}

.c {
    &-title {}
}