/* layout
------------------------------------------------------------*/
body{
	font-family: 'Noto Serif JP', serif;
	font-size: 1.4rem;
	line-height: 1.4;
}
//-------------------------------------------------------
main{
	
}

section{
	
}

//-------------------------------------------------------
.l-container{
	width: $baseWidth;
	margin: 0 auto;
}
//-------------------------------------------------------
.c-mainvisual{
	position: relative;
}


  .c-mainvisual__television{
	position: absolute;
		top: 22%;
		left: 48%;
		width: 22%;
  }