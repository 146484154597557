@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
.c-header__blue {
  height: 16.4px;
  width: 100%;
  background-color: #056FB8;
}

/* Main menu
------------------------------------------------------------*/
.c-header__list {
  padding: 0 20px;
  display: -webkit-box;
  display: flex;
  max-width: 1228.3px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 96px;
}

.c-header__logo {
  width: 219.3px;
  line-height: 1;
}

@media only screen and (max-width: 516px) {
  .c-header__logo {
    width: 200px;
  }
}

.c-header__menu {
  font-size: 1.6rem;
  max-width: 795.7px;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  width: 67%;
}

@media only screen and (max-width: 1024px) {
  .c-header__menu {
    display: none;
  }
}

.c-header__menu li {
  position: relative;
}

.c-header__menu li:hover span:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.c-header__menu span::after {
  z-index: 999;
  content: "";
  position: absolute;
  top: 114%;
  left: 0;
  width: 100%;
  height: 3px;
  background: #056FB8;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.c-header__meta {
  z-index: 1001;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  display: -webkit-box;
  display: flex;
  position: absolute;
  top: 12px;
  right: 2.6%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.p-burger {
  z-index: 1001;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: none;
}

@media only screen and (max-width: 1024px) {
  .p-burger {
    display: block;
  }
}

.c-menu__container {
  margin-top: 16.4px;
  height: 96px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.p-burger .p-burger__container {
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.p-burger .p-burger__container {
  width: 38px;
  display: -webkit-box;
  display: flex;
}

.p-burger .c-line {
  width: 38px;
  height: 2px;
  background-color: #056FB8;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.p-burger__text {
  font-weight: bolder;
  color: #056FB8;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.p-burger.is-active .c-line {
  position: absolute;
}

.p-burger .c-line.is-active {
  background-color: white;
}

.p-burger.is-active .c-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.p-burger.is-active .c-line:nth-child(2) {
  display: none;
}

.p-burger.is-active .c-line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.p-burger__text.is-active {
  color: white;
}

.c-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #056FB8;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s transform ease-in-out;
  transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-menu.is-open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.c-header .l-container {
  height: 578px;
  padding-top: 32px;
}

.c-menu__logo {
  z-index: 5;
  position: absolute;
  top: 32px;
  left: 20px;
}

.c-menu ul {
  position: absolute;
  width: 70%;
  top: 20%;
  left: 43%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.c-menu li {
  margin-bottom: 30px;
  font-size: 2rem;
  color: white;
}

body.is-hidden {
  touch-action: none;
  -ms-touch-action: none;
  overflow-y: hidden;
}

.c-header__meta {
  display: none;
}

@media only screen and (max-width: 767px) {
  .c-header__meta {
    display: block;
  }
}

@media only screen and (max-width: 516px) {
  .p-burger .p-burger__container {
    width: 35px;
  }
  .p-burger .c-line {
    width: 35px;
  }
  .p-burger__text {
    font-size: 1.1rem;
  }
}

/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer {
  padding: 71px 32px 10px;
  background-color: #056FB8;
  color: white;
}

.c-footer__logo {
  max-width: 219.3px;
  margin: 0 auto 83px;
}

.c-footer__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 1.2rem;
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  font-family: 'Noto Serif JP', serif;
  font-size: 1.4rem;
  line-height: 1.4;
}

.l-container {
  width: 1200px;
  margin: 0 auto;
}

.c-mainvisual {
  position: relative;
}

.c-mainvisual__television {
  position: absolute;
  top: 22%;
  left: 48%;
  width: 22%;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-top1__list {
  max-width: 968.6px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 132px auto 100px;
}

@media only screen and (max-width: 1024px) {
  .p-top1__list {
    padding: 0 20px;
    align-items: center;
  }
}

@media only screen and (max-width: 516px) {
  .p-top1__list {
    margin: 80px auto 100px;
  }
}

.p-top1__left {
  width: 37%;
}

@media only screen and (max-width: 1024px) {
  .p-top1__left {
    width: 49%;
  }
}

@media only screen and (max-width: 767px) {
  .p-top1__left {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-top1__title {
  font-size: 3.5rem;
}

@media only screen and (max-width: 516px) {
  .p-top1__title {
    font-size: 2.5rem;
  }
}

.p-top1__desc {
  margin: 14px 0;
  font-size: 4.5rem;
  color: #056FB8;
}

@media only screen and (max-width: 516px) {
  .p-top1__desc {
    font-size: 3rem;
  }
}

.p-top1__text {
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.8;
}

.p-top1__image {
  width: 56%;
}

@media only screen and (max-width: 1024px) {
  .p-top1__image {
    width: 46%;
  }
}

@media only screen and (max-width: 767px) {
  .p-top1__image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }
}

.p-top2 {
  background-color: #F4F4F4;
  padding: 84px 20px 147px;
  text-align: center;
}

.p-top2__content {
  max-width: 1011.2px;
  margin: 0 auto;
}

.p-top2__list {
  margin-bottom: 96px;
  margin-top: 89px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 516px) {
  .p-top2__list {
    margin-bottom: 20px;
  }
}

.p-top2__item {
  height: 119px;
  border-radius: 8px;
  width: 31%;
  background-color: #056FB8;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  font-family: 'Noto Sans JP', sans-serif;
}

@media only screen and (max-width: 1024px) {
  .p-top2__item {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .p-top2__item {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2__item {
    margin-bottom: 40px;
    width: 100%;
  }
}

.p-top2__item img {
  position: absolute;
  left: 50%;
  bottom: -20px;
  width: 25px;
  transform: translateX(-50%);
}

.p-top2__image {
  max-width: 753.4px;
  margin: 0 auto;
}

.p-top3 {
  max-width: 961.6px;
  margin: 131px auto;
}

.p-top3 a {
  margin-top: 32px;
  display: flex;
  width: 231.6px;
  height: 58px;
  background-color: #056FB8;
  color: white;
  font-size: 1.8rem;
  border-radius: 8px;
  align-items: center;
}

.p-top3 a span {
  margin-left: 40px;
  display: block;
  margin-right: 36px;
}

.p-top3 a img {
  width: 13.7px;
}

.p-top3__list {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 848.8px;
}

@media only screen and (max-width: 1024px) {
  .p-top3__list {
    margin: 0 auto;
    padding: 0 20px;
  }
}

.p-top3__left {
  width: 47%;
}

@media only screen and (max-width: 1024px) {
  .p-top3__left {
    width: 54%;
  }
}

@media only screen and (max-width: 767px) {
  .p-top3__left {
    width: 100%;
    margin-bottom: 50px;
  }
}

.p-top3__left p {
  line-height: 1.8;
  margin: 19px 0;
  font-family: 'Noto Sans JP', sans-serif;
}

.p-top3__right {
  width: 34%;
}

@media only screen and (max-width: 767px) {
  .p-top3__right {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }
}

.p-top4 {
  padding: 35px 20px 84px;
  background-color: #F7F8F8;
}

.p-top4__content {
  max-width: 961.6px;
  margin: 0 auto;
}

.p-top4__list {
  margin-bottom: 2px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 860.8px;
}

@media only screen and (max-width: 1024px) {
  .p-top4__list {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .p-top4__list {
    margin-top: 56px;
  }
}

.p-top4__left {
  margin-top: 56px;
  width: 38%;
}

@media only screen and (max-width: 767px) {
  .p-top4__left {
    width: 50%;
    margin-top: 0;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4__left {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-top4__left p {
  line-height: 1.8;
  margin-top: 24px;
  font-family: 'Noto Sans JP', sans-serif;
}

.p-top4__right {
  width: 27%;
}

@media only screen and (max-width: 767px) {
  .p-top4__right {
    width: 32%;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4__right {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }
}

.p-top4__meta {
  min-height: 108.6px;
  margin-bottom: 16px;
  background-color: #FDD23F;
  padding: 20px 36px;
  display: -webkit-box;
  display: flex;
  border-radius: 8px;
  line-height: 1.8;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
}

@media only screen and (max-width: 767px) {
  .p-top4__meta {
    padding: 20px 20px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4__meta {
    flex-wrap: wrap;
  }
}

.p-top4__meta span:first-child {
  width: 216px;
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .p-top4__meta span:first-child {
    width: 138px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top4__meta span:first-child {
    width: 100%;
    margin-bottom: 10px;
    display: block;
  }
}

.p-top4__meta span:last-child {
  width: 73%;
  font-size: 1.8rem;
}

@media only screen and (max-width: 516px) {
  .p-top4__meta span:last-child {
    width: 100%;
  }
}

.p-top4__title {
  font-size: 3rem;
  text-align: center;
  margin-top: 96px;
  margin-bottom: 68px;
}

@media only screen and (max-width: 516px) {
  .p-top4__title {
    font-size: 2.5rem;
  }
}

.p-top4__title span {
  display: block;
}

.p-top4__title img {
  width: 33.5px;
}

.p-top5 {
  padding: 0 20px;
  font-family: 'Noto Sans JP', sans-serif;
}

.p-top5 a {
  border-radius: 78px;
  background-color: #FDD23F;
  max-width: 440.2px;
  height: 80px;
  display: flex;
  margin: 36px auto 0;
  align-items: center;
  justify-content: center;
  border: 1px solid #FDD23F;
  font-family: 'Noto Serif JP', serif;
}

.p-top5 a:hover {
  background-color: white;
  color: #FDD23F;
  border: 1px solid #FDD23F;
  opacity: 1;
}

.p-top5__content {
  font-size: 2rem;
  max-width: 1040.7px;
  margin: 144px auto 157px;
  background-color: #F2F2F3;
  text-align: center;
  border-radius: 8px;
  padding: 47px 20px;
}

@media only screen and (max-width: 516px) {
  .p-top5__content {
    font-size: 1.5rem;
  }
}

.p-top5__title {
  font-size: 2.2rem;
}

.p-top5__title.sp {
  display: none;
}

@media only screen and (max-width: 516px) {
  .p-top5__title {
    display: none;
  }
  .p-top5__title.sp {
    display: block;
    font-size: 1.8rem;
  }
}

.p-top5__contact {
  font-size: 6.077rem;
  margin: 34px 0 16px;
  font-family: 'Yantramanav', sans-serif;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 516px) {
  .p-top5__contact {
    font-size: 3.4rem;
  }
}

.p-top5__contact img {
  width: 39px;
  margin-right: 11px;
}

@media only screen and (max-width: 516px) {
  .p-top5__contact img {
    width: 29px;
  }
}

/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-about__content {
  font-family: "Noto Sans JP", sans-serif;
  margin: 125px 0 292px;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .p-about__content {
    margin: 80px 0;
  }
}

.p-about__text {
  padding: 35px 0;
  display: flex;
  border-bottom: 1px solid #056fb8;
  font-size: 1.5rem;
  max-width: 922.5px;
  margin: 0 auto;
}

@media only screen and (max-width: 516px) {
  .p-about__text {
    flex-wrap: wrap;
  }
}

.p-about__left {
  width: 138px;
  margin-left: 23px;
}

@media only screen and (max-width: 516px) {
  .p-about__left {
    margin-left: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}

.p-about__right {
  width: 83%;
}

@media only screen and (max-width: 516px) {
  .p-about__right {
    width: 100%;
  }
}

.c-title {
  height: 86.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #056fb8;
  color: white;
  font-size: 2.262rem;
}

.p-flow__content {
  margin: 148px 0 381px;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .p-flow__content {
    margin: 80px 0;
  }
}

.p-flow__meta {
  max-width: 922.5px;
  margin: 0 auto;
  display: -webkit-box;
  display: flex;
  height: 229.7px;
  border: 1px solid #056fb8;
}

@media only screen and (max-width: 1024px) {
  .p-flow__meta {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .p-flow__meta {
    flex-wrap: wrap;
  }
}

.p-flow__number {
  background-color: #056fb8;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 5.027rem;
  color: white;
  width: 129.5px;
}

@media only screen and (max-width: 767px) {
  .p-flow__number {
    width: 100%;
    font-size: 4rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-flow__number {
    font-size: 3.5rem;
  }
}

.p-flow__text {
  padding: 36px 50px;
  width: 86%;
}

@media only screen and (max-width: 1024px) {
  .p-flow__text {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .p-flow__text {
    padding: 30px 20px;
    width: 100%;
  }
}

.p-flow__title {
  font-size: 2.424rem;
}

@media only screen and (max-width: 516px) {
  .p-flow__title {
    font-size: 2rem;
  }
}

.p-flow__info {
  margin-top: 11px;
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.8;
}

.p-contact {
  margin-top: 28px;
  color: #056fb8;
  font-family: "Noto Sans JP", sans-serif;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 567px;
}

@media only screen and (max-width: 767px) {
  .p-contact {
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.p-contact__left {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .p-contact__left {
    width: 100%;
    margin-bottom: 20px;
  }
}

.p-contact__text {
  font-size: 1.6rem;
}

@media only screen and (max-width: 516px) {
  .p-contact__text {
    font-size: 1.5rem;
  }
}

.p-conatct__phone {
  font-size: 3.368rem;
  font-family: "Yantramanav", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 516px) {
  .p-conatct__phone {
    font-size: 3.2rem;
  }
}

.p-conatct__phone img {
  width: 21.5px;
  margin-right: 8px;
}

.p-conatct__phone span {
  letter-spacing: 2px;
}

.p-contact__right {
  text-align: center;
}

.p-contact__link {
  margin-top: 6px;
  width: 239.3px;
  height: 30px;
  background-color: #056fb8;
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 5px;
}

.p-flow__arrow {
  width: 73.2px;
  margin: 53px auto;
}

@media only screen and (max-width: 767px) {
  .p-flow__arrow {
    width: 60.2px;
    margin: 40px auto;
  }
}

.p-flow__list {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 516px) {
  .p-flow__list {
    flex-wrap: wrap;
  }
}

.p-flow__left {
  width: 61%;
}

@media only screen and (max-width: 516px) {
  .p-flow__left {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-flow__right1 {
  margin-right: -14px;
  width: 25%;
  margin-top: -5px;
}

@media only screen and (max-width: 767px) {
  .p-flow__right1 {
    margin-right: 0;
    width: 30%;
    margin-top: 0;
  }
}

@media only screen and (max-width: 516px) {
  .p-flow__right1 {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }
}

.p-flow__right2 {
  margin-top: -22px;
  width: 22%;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .p-flow__right2 {
    margin-top: 0;
    width: 30%;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 516px) {
  .p-flow__right2 {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }
}

.p-flow__right3 {
  margin-top: 12px;
  width: 26%;
}

@media only screen and (max-width: 767px) {
  .p-flow__right3 {
    margin-top: 0;
    width: 30%;
  }
}

@media only screen and (max-width: 516px) {
  .p-flow__right3 {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }
}

.p-service__content {
  max-width: 961.9px;
  margin: 162px auto 117px;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .p-service__content {
    margin: 80px auto;
  }
}

.p-service__title {
  font-size: 3.5rem;
}

@media only screen and (max-width: 767px) {
  .p-service__title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 516px) {
  .p-service__title {
    font-size: 2.5rem;
  }
}

.p-service__list {
  margin-top: 66px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-service__image {
  width: 53%;
}

@media only screen and (max-width: 767px) {
  .p-service__image {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-service__text {
  text-align: center;
  margin-top: 5px;
  width: 40%;
  font-size: 2.5rem;
}

@media only screen and (max-width: 767px) {
  .p-service__text {
    width: 100%;
  }
}

.p-service__text p {
  margin-top: 10px;
  border: 2px solid;
  background-color: #FDD23F;
  font-size: 2.04rem;
  font-family: "Noto Sans JP", sans-serif;
  border-radius: 8px;
  line-height: 2;
  text-align: left;
  padding: 35px 27px;
}

@media only screen and (max-width: 516px) {
  .p-service__text p {
    font-size: 1.57rem;
    padding: 35px 20px;
  }
}

.p-service1 {
  padding-bottom: 100px;
  padding-top: 86px;
  background-color: #F4F4F4;
}

.p-service1__content {
  max-width: 961.9px;
  margin: 0 auto;
  padding: 0 20px;
}

@media only screen and (max-width: 1024px) {
  .p-service1__content {
    max-width: 580px;
  }
}

.p-service1__list {
  margin-left: 15px;
  margin-top: 64px;
  font-family: "Noto Sans JP", sans-serif;
  display: -webkit-box;
  display: flex;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .p-service1__list {
    display: block;
  }
}

@media only screen and (max-width: 516px) {
  .p-service1__list {
    margin-left: 0;
  }
}

.p-service1__left {
  width: 59%;
  font-size: 2rem;
}

@media only screen and (max-width: 1024px) {
  .p-service1__left {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 516px) {
  .p-service1__left {
    font-size: 1.55rem;
  }
}

.p-service1__text {
  margin-bottom: 57px;
}

.p-service1__text span {
  display: inline-block;
  border-radius: 5px;
  background-color: #056fb8;
  color: white;
  padding: 11px 23px;
  margin-bottom: 12px;
}

.p-service1__text p {
  line-height: 2.3;
}

.p-service1__image {
  width: 46%;
  position: absolute;
  right: 7px;
}

@media only screen and (max-width: 1024px) {
  .p-service1__image {
    position: relative;
    width: 419.9px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 516px) {
  .p-service1__image {
    width: 279.9px;
    right: 0;
  }
}

.p-service1__chat {
  position: relative;
}

.p-service1__chat span {
  top: 12%;
  position: absolute;
  line-height: 1.8;
  left: 9.5%;
  letter-spacing: 1px;
}

@media only screen and (max-width: 516px) {
  .p-service1__chat span {
    left: 8%;
    font-size: 1rem;
  }
}

.p-service2 {
  max-width: 961.9px;
  margin: 112px auto 180px;
  padding: 0 20px;
}

@media only screen and (max-width: 516px) {
  .p-service2 {
    margin: 80px auto;
  }
}

.p-service2__content {
  margin: 61px 0 61px 16px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 2rem;
}

@media only screen and (max-width: 1024px) {
  .p-service2__content {
    margin: 61px 0;
  }
}

@media only screen and (max-width: 516px) {
  .p-service2__content {
    font-size: 1.55rem;
  }
}

.p-service2__content table,
.p-service2__content tr,
.p-service2__content td {
  border: 1px solid;
  border-collapse: collapse;
}

.p-service2__content table {
  text-align: center;
  margin-top: 38px;
  width: 100%;
  margin-bottom: 85px;
}

.p-service2__content table tr:nth-child(n+2) {
  height: 70px;
}

.p-service2__info {
  border-radius: 5px;
  background-color: #056fb8;
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 171.3px;
  height: 50.4px;
}

.p-service2__table {
  height: 90px;
  font-size: 2.3rem;
  background-color: #FDD23F;
}

@media only screen and (max-width: 767px) {
  .p-service2__table {
    font-size: 1.8rem;
  }
}

.p-service2__table td:first-child {
  width: 41%;
}

.p-service2__table td:last-child {
  width: 57%;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
