/* Header
------------------------------------------------------------*/
.c-header{
   &__blue{
       height: 16.4px;
       width: 100%;
       background-color: #056FB8;
   }
}
/* Main menu
------------------------------------------------------------*/
.c-gnav{

}
.c {
    &-header__list{
        padding: 0 20px;
        display: -webkit-box;
        display: flex;
        max-width: 1228.3px;
        -webkit-box-pack: justify;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        height: 96px;
  }
    &-header__logo{
  width: 219.3px;
  line-height: 1;
  @media only screen and (max-width: 516px){
  width: 200px;
  }
  }
    &-header__menu{
        font-size: 1.6rem;
        max-width: 795.7px;
        display: -webkit-box;
        display: flex;
        justify-content: space-between;
        width: 67%;
        @media only screen and (max-width: 1024px){
        display: none;
        }
        li{
           position: relative;
        }
        li:hover span:after {
         -webkit-transform: scaleX(1);
         transform: scaleX(1);
      }
      span::after {
         z-index: 999;
         content: "";
         position: absolute;
         
    top: 114%;

         left: 0;
         width: 100%;
         height: 3px;
         background: #056FB8;
         -webkit-transform: scaleX(0);
         transform: scaleX(0);
         -webkit-transition: -webkit-transform 0.3s ease;
         transition: -webkit-transform 0.3s ease;
         transition: transform 0.3s ease;
         transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      }
  }
  }

  .c-header__meta {
    z-index: 1001;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    display: -webkit-box;
    display: flex;
    position: absolute;
    top: 12px;
    right: 2.6%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
 }
 .p-burger {
    z-index: 1001;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: none;
    @media only screen and (max-width: 1024px){
    display: block;
    }
 }
 .c-menu__container{
    margin-top: 16.4px;
    height: 96px;
    display: flex;
    align-items: center;
    padding: 0 20px;
 }
 .p-burger .p-burger__container {
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 30px;
    position: relative;
    cursor: pointer;
 }
 .p-burger .p-burger__container {
    width: 38px;
    display: -webkit-box;
    display: flex;
 }
 .p-burger .c-line {
    width: 38px;
    height: 2px;
    background-color: #056FB8;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
 }
 .p-burger__text {
    font-weight: bolder;
    color: #056FB8;
    text-transform: uppercase;
    letter-spacing: 1px;
 }
 .p-burger.is-active .c-line {
    position: absolute;
 }
 .p-burger .c-line.is-active {
    background-color: white;
 }
 .p-burger.is-active .c-line:nth-child(1) {
    -webkit-transform: rotate(
 45deg
 );
    transform: rotate(
 45deg
 );
 }
 .p-burger.is-active .c-line:nth-child(2) {
    display: none;
 }
 .p-burger.is-active .c-line:nth-child(3) {
    -webkit-transform: rotate(
 -45deg
 );
    transform: rotate(
 -45deg
 );
 }
 .p-burger__text.is-active {
    color: white;
 }
 .c-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #056FB8;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s transform ease-in-out;
    transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
 }
 .c-menu.is-open {
    -webkit-transform: translateY(0);
    transform: translateY(0);
 }
 .c-header .l-container {
    height: 578px;
    padding-top: 32px;
 }
 .c-menu__logo {
    z-index: 5;
    position: absolute;
    top: 32px;
    left: 20px;
 }
 .c-menu ul {
    position: absolute;
    width: 70%;
    top: 20%;
    left: 43%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
 }
 .c-menu li {
    margin-bottom: 30px;
    font-size: 2rem;
    color: white;
 }
 body.is-hidden {
 touch-action: none;
     -ms-touch-action: none;
    overflow-y: hidden;
 }
 .c-header__meta{
    display: none;
 }
 @media only screen and (max-width: 767px) {
    .c-header__meta{
       display: block;
    }
 }
 @media only screen and (max-width: 516px)
 {
    .p-burger .p-burger__container {
       width: 35px;
   }
   .p-burger .c-line {
    width: 35px;
 }
 .p-burger__text {
    font-size: 1.1rem;
 }
 
 }